import { useState } from "react";

export const myPageDataEnum = {
  headerTitle: "headerTitle",
  companyName: "companyName",
  companyDescription: "companyDescription",
  aboutMeTitle: "aboutMeTitle",
  aboutMe: "aboutMe",
  logo: "logo",
  serviceItemsTitle: "serviceItemsTitle",
  serviceItems: "serviceItems",
  otherTitle: "otherTitle",
  otherImg: "otherImg",
  phone: "phone",
  email: "email",
  socialUrl: "socialUrl",
  owner: "owner",
  addressFreeForm: "addressFreeForm",
  accentColor: "accentColor",
  backgroundImg: "backgroundImg",
  otherItems: "otherItems",
};

export function usePreviewData(inputData) {
  const myPageData = new Map();

  // Company Info
  const [headerTitle, setHeaderTitle] = useState(inputData?.headerTitle);
  myPageData.set(myPageDataEnum.headerTitle, {
    value: headerTitle,
    setter: setHeaderTitle,
  });

  const [companyName, setCompanyName] = useState(inputData?.companyName);
  myPageData.set(myPageDataEnum.companyName, {
    value: companyName,
    setter: setCompanyName,
  });

  const [companyDescription, setCompanyDescription] = useState(
    inputData?.companyDescription,
  );
  myPageData.set(myPageDataEnum.companyDescription, {
    value: companyDescription,
    setter: setCompanyDescription,
  });

  const [logo, setLogo] = useState(inputData?.logo);
  myPageData.set(myPageDataEnum.logo, { value: logo, setter: setLogo });

  const [aboutMeTitle, setAboutMeTitle] = useState(inputData?.aboutMeTitle);
  myPageData.set(myPageDataEnum.aboutMeTitle, {
    value: aboutMeTitle,
    setter: setAboutMeTitle,
  });
  const [aboutMe, setAboutMe] = useState(inputData?.aboutMe);
  myPageData.set(myPageDataEnum.aboutMe, {
    value: aboutMe,
    setter: setAboutMe,
  });

  // Contact Info
  const [phone, setPhone] = useState(inputData?.phone);
  myPageData.set(myPageDataEnum.phone, { value: phone, setter: setPhone });
  const [email, setEmail] = useState(inputData?.email);
  myPageData.set(myPageDataEnum.email, { value: email, setter: setEmail });
  const [owner, setOwner] = useState(inputData?.owner);
  myPageData.set(myPageDataEnum.owner, { value: owner, setter: setOwner });
  const [addressFreeForm, setAddressFreeForm] = useState(
    inputData?.addressFreeForm,
  );
  myPageData.set(myPageDataEnum.addressFreeForm, {
    value: addressFreeForm,
    setter: setAddressFreeForm,
  });
  const [socialUrl, setSocialUrl] = useState(inputData?.socialUrl);
  myPageData.set(myPageDataEnum.socialUrl, {
    value: socialUrl,
    setter: setSocialUrl,
  });

  // Service Items
  const [serviceItemsTitle, setServiceItemsTitle] = useState(
    inputData?.serviceItemsTitle,
  );
  myPageData.set(myPageDataEnum.serviceItemsTitle, {
    value: serviceItemsTitle,
    setter: setServiceItemsTitle,
  });

  const [serviceItems, setServiceItems] = useState(
    inputData?.serviceItems ?? [],
  );
  myPageData.set(myPageDataEnum.serviceItems, {
    value: serviceItems,
    setter: setServiceItems,
  });

  // Misc Page Data
  const [otherTitle, setOtherTitle] = useState(inputData?.otherTitle);
  myPageData.set(myPageDataEnum.otherTitle, {
    value: otherTitle,
    setter: setOtherTitle,
  });

  const [otherImg, setOtherImg] = useState(inputData?.otherImg);
  myPageData.set(myPageDataEnum.otherImg, {
    value: otherImg,
    setter: setOtherImg,
  });

  // TODO: Set up other items instead of the ContactItems... then make sure the Contact Data is displayed explicitly in Preview...
  const [otherItems, setOtherItems] = useState(inputData?.otherItems ?? []);
  myPageData.set(myPageDataEnum.otherItems, {
    value: otherItems,
    setter: setOtherItems,
  });

  // Advanced page settings
  const [backgroundImg, setBackgroundImg] = useState(
    inputData?.backgroundImg ?? `${process.env.PUBLIC_URL}/default-bg.jpg`,
  );
  myPageData.set(myPageDataEnum.backgroundImg, {
    value: backgroundImg,
    setter: setBackgroundImg,
  });

  const [accentColor, setAccentColor] = useState(
    inputData?.accentColor ?? "#F7F7F7",
  );
  myPageData.set(myPageDataEnum.accentColor, {
    value: accentColor,
    setter: setAccentColor,
  });

  return {
    myPageData,
  };
}
