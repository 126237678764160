import React, { useState, useRef } from "react";

// inputItem Map<value, setter()>
// saveAction? () => void
export function RenderEditItem({
  editModeEnabled,
  inputItem,
  textAreaRows = 0,
  saveAction = undefined,
}) {
  const [editTarget, setEditTarget] = useState(false);

  function toggleOffAndSave() {
    setEditTarget(false);
    // Trigger additional save action if provided
    saveAction?.();
  }

  return editModeEnabled ? (
    <div
      onBlur={() => toggleOffAndSave()}
      className={`editSelector ${editTarget ? "editActive" : "preEditSelector"}`}
      onClick={() => setEditTarget(true)}
    >
      {editTarget ? (
        <div>
          {textAreaRows > 0 ? (
            <textarea
              name="editTextArea"
              className="editTextArea"
              rows={textAreaRows}
              placeholder="Enter Text"
              defaultValue={inputItem?.value}
              onChange={val => inputItem?.setter(val.target.value)}
              autoFocus
            />
          ) : (
            <input
              name="editInput"
              placeholder="Enter Text"
              defaultValue={inputItem?.value}
              onChange={val => inputItem?.setter(() => val.target.value)}
              autoFocus
            />
          )}
        </div>
      ) : inputItem?.value ? (
        inputItem.value
      ) : (
        <div className="editActive editEmpty">click to edit</div>
      )}
    </div>
  ) : (
    <div>{inputItem?.value}</div>
  );
}

// inputSrc is Map<value, setter()>
export function RenderEditImage({
  editModeEnabled,
  inputSrc,
  altText = "",
  editToolsOutside = false,
  itemClass = "",
  saveAction = undefined,
}) {
  const inputFileRef = useRef();
  const [editTarget, setEditTarget] = useState(false);

  function handleRemoveAndClose() {
    setEditTarget(false);
    inputSrc?.setter("");
    // Trigger additional save action if provided
    saveAction?.("empty");
  }

  function handleInputFile() {
    // @ts-ignore
    inputFileRef.current.click();
  }

  function handleOpenEdit(hasImage = false) {
    if (hasImage) {
      setEditTarget(val => !val);
    } else {
      handleInputFile();
    }
  }
  return (
    <div className={itemClass}>
      {editModeEnabled ? (
        <div className="editImage">
          <input
            type="file"
            ref={inputFileRef}
            accept="image/*"
            onChange={event => {
              // TODO: Will need to upload to s3 for proper url and storage
              console.log(event.target.files[0]);
              const tempImgPath = `${URL.createObjectURL(event.target.files[0])}`;
              inputSrc?.setter(tempImgPath);
              setEditTarget(false);
              // Trigger additional save action if provided
              saveAction?.(tempImgPath);
            }}
            style={{ display: "none" }}
          />
          {editTarget && !editToolsOutside && (
            <button
              type="button"
              className="editCloseButton editImageAddButton"
              onClick={() => handleInputFile()}
            >
              Upload New Image
            </button>
          )}
          {editTarget && !editToolsOutside && (
            <button
              type="button"
              className="editCloseButton editImageRemoveButton"
              onClick={() => handleRemoveAndClose()}
            >
              Clear Image
            </button>
          )}
          {/* eslint-disable-next-line */}
          <img
            className={`editSelector ${editTarget ? "editActive" : "preEditSelector"}`}
            onClick={() => handleOpenEdit(inputSrc?.value)}
            src={inputSrc?.value}
            alt={`🖼️ Add image for "${altText}"`}
          />
          {editTarget && editToolsOutside && (
            <button
              type="button"
              className="editCloseButton"
              onClick={() => handleInputFile()}
            >
              Upload New Image
            </button>
          )}
          {editTarget && editToolsOutside && (
            <button
              type="button"
              className="editCloseButton"
              onClick={() => handleRemoveAndClose()}
            >
              Clear Image
            </button>
          )}
        </div>
      ) : (
        inputSrc?.value && <img src={inputSrc?.value} alt="" />
      )}
    </div>
  );
}
