import React from "react";
import { Link } from "react-router-dom";

import "./Account.css";
import { useAuth } from "../../../auth/AuthProvider";

export default function Account() {
  const { session } = useAuth();

  return (
    <div className="accountContainer">
      <h1>Account</h1>
      <Link className="homeButton" to="/logout">
        Logout
      </Link>
      <p>
        This is pretty bare-bones but you can checkout a few of the modes to
        play around with.
      </p>
      <p>
        <b>Email:</b> {session?.user?.email}
      </p>
      <p>
        <b>Account created:</b> {session?.user?.created_at}
      </p>
      <p>
        <b>Last sign in at:</b> {session?.user?.last_sign_in_at}
      </p>
    </div>
  );
}
