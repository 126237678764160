import React, { useState } from "react";
import { RenderEditItem, RenderEditImage } from "./components/RenderEdits";
import { RenderSectionItems } from "./components/RenderItems";

import "./Preview.css";
import { myPageDataEnum } from "../../hooks/usePreviewData";

function Preview({
  webPalette,
  webBackground,
  editModeEnabled,
  toggleEditMode,
  myPageData,
}) {
  const [aboutMeSectionEnabled, setAboutMeSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.aboutMe)?.value,
  );
  const [serviceSectionEnabled, setServiceSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.serviceItems)?.value.length > 0,
  );
  const [bottomSectionEnabled, setBottomSectionEnabled] = useState(
    myPageData.get(myPageDataEnum.otherItems)?.value.length > 0,
  );

  const primaryColor = ["247, 247, 247", "247, 247, 247"];
  const secondaryColor = ["247, 247, 247", "110, 110, 110"];
  const primaryFont = "247, 247, 247";
  const secondaryFont = "0, 0, 0";

  return (
    <div
      id="webpage"
      className="webpage"
      style={{ backgroundImage: `url(${webBackground})` }}
    >
      <div id="top" />
      <div className="topHeader">
        <div className="topHeaderContent">
          <div className="topHeaderTitle">
            <a href={!editModeEnabled && "#top"}>
              <div className="topHeaderImg">
                <RenderEditImage
                  inputSrc={myPageData.get(myPageDataEnum.logo)}
                  altText="logo"
                  editModeEnabled={editModeEnabled}
                  editToolsOutside
                />
              </div>
            </a>
            <a href={!editModeEnabled && "#top"}>
              <h2>
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.headerTitle)}
                  editModeEnabled={editModeEnabled}
                />
              </h2>
            </a>
          </div>
          <div className="topHeaderMenu">
            {aboutMeSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${myPageData.get(myPageDataEnum.aboutMeTitle)?.value}`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
                </a>
              </div>
            )}
            {serviceSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${
                    myPageData.get(myPageDataEnum.serviceItemsTitle)?.value
                  }`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
                </a>
              </div>
            )}
            {bottomSectionEnabled && (
              <div className="topHeaderMenuItem">
                <a
                  style={{ color: `rgb(${secondaryFont})` }}
                  href={`#${myPageData.get(myPageDataEnum.otherTitle)?.value}`}
                  rel="author"
                >
                  {myPageData.get(myPageDataEnum.otherTitle)?.value}
                </a>
              </div>
            )}
            <div className="topHeaderMenuItem">
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href="#footer"
                rel="author"
              >
                Contact Us
              </a>
            </div>
          </div>
          <div className="topHeaderMenuMobile">
            <a
              style={{ color: `rgb(${secondaryFont})` }}
              href="#footer"
              rel="author"
            >
              Contact Us
            </a>
          </div>
          <div className="topHeaderContact">
            <div>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  `mailto:${myPageData.get(myPageDataEnum.email)?.value}`
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.email)}
                  editModeEnabled={editModeEnabled}
                />
              </a>
            </div>
            <div>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  `tel:${myPageData.get(myPageDataEnum.phone)?.value}`
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.phone)}
                  editModeEnabled={editModeEnabled}
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  myPageData.get(myPageDataEnum.socialUrl)?.value
                }
                rel="noreferrer"
              >
                {editModeEnabled ? (
                  <RenderEditItem
                    inputItem={myPageData.get(myPageDataEnum.socialUrl)}
                    editModeEnabled={editModeEnabled}
                  />
                ) : (
                  "social"
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="editModeHeader" hidden={!editModeEnabled}>
        Edit Mode Enabled{" "}
        <button type="button" onClick={() => toggleEditMode()}>
          Click to close edit mode
        </button>
      </div>
      <div
        className="top"
        style={{
          backgroundColor: `rgba(${primaryColor[webPalette]}, 0.2)`,
          color: `rgb(${primaryFont})`,
        }}
      >
        <div className="topContainer">
          <div className="topTitle">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.companyName)}
                textAreaRows={2}
                editModeEnabled={editModeEnabled}
              />
            </h1>
          </div>
          {(myPageData.get(myPageDataEnum.companyDescription)?.value ||
            editModeEnabled) && (
            <div className="topDesc">
              <h2>
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.companyDescription)}
                  textAreaRows={4}
                  editModeEnabled={editModeEnabled}
                />
              </h2>
            </div>
          )}
          <div className="coreButton">
            {(aboutMeSectionEnabled ||
              serviceSectionEnabled ||
              bottomSectionEnabled) && (
              <a
                className="mainButton"
                style={{
                  backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
                  color: `rgb(${secondaryFont})`,
                }}
                href={`#${
                  aboutMeSectionEnabled
                    ? myPageData.get(myPageDataEnum.aboutMeTitle)?.value
                    : serviceSectionEnabled
                      ? myPageData.get(myPageDataEnum.serviceItemsTitle)?.value
                      : "footer"
                }`}
                rel="author"
              >
                Learn More
              </a>
            )}
          </div>
        </div>
      </div>
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setAboutMeSectionEnabled(val => !val)}
        >
          {aboutMeSectionEnabled
            ? "Remove About Me Section"
            : "Enable About Me Section"}
        </button>
      )}
      {aboutMeSectionEnabled && (
        <div
          className="aboutMe"
          id={myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
          style={{
            backgroundColor: `rgba(${secondaryColor[webPalette]}, 1)`,
            color: `rgb(${secondaryFont})`,
          }}
        >
          <div className="aboutMeContent">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.aboutMeTitle)}
                editModeEnabled={editModeEnabled}
              />
            </h1>
            <p>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.aboutMe)}
                editModeEnabled={editModeEnabled}
                textAreaRows={5}
              />
            </p>
          </div>
          <div className="coreButton">
            {serviceSectionEnabled && (
              <a
                className="mainButton"
                style={{
                  backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
                  color: `rgb(${secondaryFont})`,
                }}
                href={`#${myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
              </a>
            )}
          </div>
        </div>
      )}
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setServiceSectionEnabled(val => !val)}
        >
          {serviceSectionEnabled
            ? "Remove Service Section"
            : "Enable Service Section"}
        </button>
      )}
      {serviceSectionEnabled && (
        <div
          className="mid"
          id={myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
          style={{
            backgroundColor: `rgba(${secondaryColor[webPalette]}, 0.9)`,
            color: `rgb(${secondaryFont})`,
          }}
        >
          <div className="midTitle">
            <h1>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.serviceItemsTitle)}
                editModeEnabled={editModeEnabled}
              />
            </h1>
          </div>
          <div className="midItems">
            <RenderSectionItems
              inputItems={myPageData.get(myPageDataEnum.serviceItems)}
              editModeEnabled={editModeEnabled}
              enableImgs
              itemClass="midItem"
            />
          </div>
        </div>
      )}
      {editModeEnabled && (
        <button
          type="button"
          className="editButton"
          onClick={() => setBottomSectionEnabled(val => !val)}
        >
          {bottomSectionEnabled
            ? "Remove Bottom Section"
            : "Enable Bottom Section"}
        </button>
      )}
      {bottomSectionEnabled && (
        <div
          className="otherSection"
          id={myPageData.get(myPageDataEnum.otherTitle)?.value}
          style={{
            backgroundColor: `rgba(${primaryColor[webPalette]}, 1)`,
            color: `rgb(${primaryFont})`,
          }}
        >
          <div className="otherTitle">
            <h1 style={{ color: `rgb(${secondaryFont})` }}>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.otherTitle)}
                editModeEnabled={editModeEnabled}
              />
            </h1>
          </div>
          <div className="otherContent">
            <div className="otherItems">
              {/* Add more robust icon solution */}
              <RenderSectionItems
                inputItems={myPageData.get(myPageDataEnum.otherItems)}
                editModeEnabled={editModeEnabled}
                enableIcons={false}
                itemClass="otherItem"
                accentColor={myPageData.get(myPageDataEnum.accentColor)?.value}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="footer"
        id="footer"
        style={{
          backgroundColor: `${myPageData.get(myPageDataEnum.accentColor)?.value}`,
          color: `rgb(${secondaryFont})`,
        }}
      >
        <div className="footerContent">
          <div className="footerImg">
            <RenderEditImage
              inputSrc={myPageData.get(myPageDataEnum.otherImg)}
              altText={myPageData.get(myPageDataEnum.otherTitle)?.value}
              editModeEnabled={editModeEnabled}
            />
            <h3>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.companyName)}
                textAreaRows={2}
                editModeEnabled={editModeEnabled}
              />
            </h3>
          </div>
          <div className="footerContact">
            <h4>Contact Us</h4>
            <p>
              <RenderEditItem
                inputItem={myPageData.get(myPageDataEnum.addressFreeForm)}
                editModeEnabled={editModeEnabled}
              />
            </p>
            <p>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  `mailto:${myPageData.get(myPageDataEnum.email)?.value}`
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.email)}
                  editModeEnabled={editModeEnabled}
                />
              </a>
            </p>
            <p>
              <a
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  `tel:${myPageData.get(myPageDataEnum.phone)?.value}`
                }
              >
                <RenderEditItem
                  inputItem={myPageData.get(myPageDataEnum.phone)}
                  editModeEnabled={editModeEnabled}
                />
              </a>
            </p>
            <p>
              <a
                target="_blank"
                style={{ color: `rgb(${secondaryFont})` }}
                href={
                  !editModeEnabled &&
                  myPageData.get(myPageDataEnum.socialUrl)?.value
                }
                rel="noreferrer"
              >
                {editModeEnabled ? (
                  <RenderEditItem
                    inputItem={myPageData.get(myPageDataEnum.socialUrl)}
                    editModeEnabled={editModeEnabled}
                  />
                ) : (
                  "social media"
                )}
              </a>
            </p>
          </div>
          <div className="footerSitemap">
            <h4>Sitemap</h4>
            {aboutMeSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.aboutMeTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.aboutMeTitle)?.value}
              </a>
            )}
            {serviceSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.serviceItemsTitle)?.value}
              </a>
            )}
            {bottomSectionEnabled && (
              <a
                href={`#${myPageData.get(myPageDataEnum.otherTitle)?.value}`}
                rel="author"
              >
                {myPageData.get(myPageDataEnum.otherTitle)?.value}
              </a>
            )}
          </div>
        </div>
        <div className="footerBottom">
          <p>
            Webpage created with{" "}
            <a
              href="https://easyzilla.com"
              style={{ color: `rgb(${secondaryFont})` }}
              rel="author"
            >
              <b>easyzilla</b>
            </a>
          </p>
          <div
            style={{
              display:
                !serviceSectionEnabled && !bottomSectionEnabled
                  ? "none"
                  : "flex",
            }}
            className="toTopButton"
          >
            <a
              href="#top"
              style={{ color: `rgb(${secondaryFont})` }}
              rel="author"
            >
              <b>back to top</b>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
