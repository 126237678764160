import React from "react";
import PreviewWrapper from "../../components/Preview/PreviewWrapper";

export default function Example() {
  const exampleData = {
    headerTitle: "Red Deer Irrigation Residential",
    companyName: "Red Deer Irrigation Residential",
    companyDescription: "Central Alberta's lawn sprinkler specialists",
    aboutMeTitle: "Local and personalized",
    aboutMe:
      "From design to installation and everything in between, we will work closely with you to create a customized irrigation system that meets your needs and fits your budget. " +
      "And because we are local to Red Deer, you can trust that we know the unique challenges and opportunities that come with our climate and soil conditions. " +
      "So why wait? Let's turn your outdoor space into the envy of the neighborhood. Contact Red Deer Irrigation Residential today to schedule your consultation and take the first step towards a greener, healthier lawn and garden.",
    logo: `${process.env.PUBLIC_URL}/darcy/logo-drop.png`,
    serviceItemsTitle: "Services",
    serviceItems: [
      {
        index: 0,
        title: "System Design and Installation",
        description:
          "Residential irrigation system design and installation require careful planning, considering factors like water source, terrain, and plant types for efficient water distribution.",
        img: "/darcy/sprinklers_multi.jpg",
      },
      {
        index: 1,
        title: "Spring Start Ups",
        description:
          "Activating systems, checking for leaks or damage, adjusting settings, and ensuring proper functionality for the upcoming season.",
        img: "/darcy/sprinkler_small.jpg",
      },
      {
        index: 2,
        title: "Maintenance",
        description:
          "Regular inspection, cleaning, and repair to ensure optimal performance, water efficiency, and plant health throughout the year.",
        img: "/darcy/sprinkler_gloves.jpg",
      },
      {
        index: 3,
        title: "Winterization",
        description:
          "Involves draining water from the system (blow outs), insulating vulnerable components, and shutting off valves to prevent freezing damage during cold weather.",
        img: "/darcy/sprinkler_winterize.jpg",
      },
    ],
    otherTitle: "FAQ",
    otherItems: [
      {
        title: "Receivable Payments",
        description: "cash, cheque, or etransfer",
      },
    ],
    email: "reddeerirrigationres@gmail.com",
    phone: "(403) 506-0909",
    socialUrl: "https://www.facebook.com/RedDeerIrrigationResidential/",
    owner: "Darcy Driedger",
    addressFreeForm: "Red Deer, Alberta",
    otherImg: `${process.env.PUBLIC_URL}/darcy/logo.png`,
    backgroundImg: `${process.env.PUBLIC_URL}/darcy/bg.jpg`,
    accentColor: "#BCFD4C",
  };
  return <PreviewWrapper previewData={exampleData} />;
}
