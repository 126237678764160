/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";
import { RenderEditItem, RenderEditImage } from "./RenderEdits";
import { iconRenderMapper, iconNameMapper } from "../../../util/iconMapper";

// inputItems<value[], setter()>
export function RenderSectionItems({
  editModeEnabled,
  inputItems,
  enableImgs = false,
  enableIcons = false,
  itemClass = "",
  accentColor = "",
}) {
  if (!inputItems) {
    return <div />;
  }
  let itemCount = 0;
  function addItem() {
    const newItem = { index: itemCount, title: "", description: "" };
    inputItems.setter(val => val.concat(newItem));
  }

  function removeItem() {
    inputItems.setter(val => val.slice(0, -1));
    itemCount -= 1;
  }

  const renderItems = inputItems.value.map(item => {
    itemCount += 1;
    return (
      <div key={itemCount}>
        <RenderSectionItem
          sectionItem={item}
          itemCount={itemCount}
          editModeEnabled={editModeEnabled}
          enableImgs={enableImgs}
          enableIcons={enableIcons}
          itemClass={itemClass}
          accentColor={accentColor}
          parentItems={inputItems}
        />
      </div>
    );
  });
  return (
    <>
      {renderItems}
      {editModeEnabled && (
        <>
          <div className="editBreak" />
          <div className="editButtonGroup">
            <button
              type="button"
              className="editButton"
              onClick={() => addItem()}
            >
              ➕ Add New Item
            </button>
            <button
              type="button"
              className="editButton"
              onClick={() => removeItem()}
            >
              ➖ Remove Last Item
            </button>
          </div>
        </>
      )}
    </>
  );
}

// sectionItem{ value, description, img }
// parentItems Map<value[], setter()>
export function RenderSectionItem({
  editModeEnabled,
  sectionItem,
  itemCount = 0,
  enableImgs = false,
  enableIcons = false,
  itemClass = "",
  accentColor = "",
  parentItems = undefined,
}) {
  const [myTitle, setMyTitle] = useState(sectionItem.title);
  const [myDescription, setMyDescription] = useState(sectionItem.description);
  const [myImg, setMyImg] = useState(sectionItem.img);
  const itemIcon = iconNameMapper(myTitle);

  // Save to parent
  const saveToParent = imgOverride => {
    if (parentItems?.value && parentItems.value.length > 0) {
      const updatedParentItems = parentItems.value.map(pItem => {
        // Find matching index and update otherwise return existing value
        if (pItem.index === sectionItem.index) {
          return {
            index: sectionItem.index,
            title: myTitle,
            description: myDescription,
            img: imgOverride || myImg,
          };
        }
        return pItem;
      });
      parentItems.setter(updatedParentItems);
    }
  };
  const isEven = itemCount % 2 === 0;
  return (
    <div className={`renderItem ${isEven ? "rightItem" : "leftItem"}`}>
      {enableImgs && !isEven && (
        <RenderEditImage
          inputSrc={{ value: myImg, setter: setMyImg }}
          altText={myTitle}
          editModeEnabled={editModeEnabled}
          itemClass={itemClass}
          saveAction={saveToParent}
        />
      )}
      <div className={itemClass}>
        <div className="renderItemText">
          {enableIcons && itemIcon && (
            <div className="renderItemIcon" style={{ color: accentColor }}>
              {iconRenderMapper(itemIcon)}
            </div>
          )}
          <h2>
            <RenderEditItem
              inputItem={{ value: myTitle, setter: setMyTitle }}
              editModeEnabled={editModeEnabled}
              saveAction={saveToParent}
            />
          </h2>
          <p>
            <RenderEditItem
              inputItem={{ value: myDescription, setter: setMyDescription }}
              textAreaRows={4}
              editModeEnabled={editModeEnabled}
              saveAction={saveToParent}
            />
          </p>
        </div>
      </div>
      {enableImgs && isEven && (
        <RenderEditImage
          inputSrc={{ value: myImg, setter: setMyImg }}
          altText={myTitle}
          editModeEnabled={editModeEnabled}
          itemClass={itemClass}
          saveAction={saveToParent}
        />
      )}
    </div>
  );
}
