import { useState } from "react";

export default function useLocalStorage(keyName, defaultValue) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // eslint-disable-next-line no-undef
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      }
      // eslint-disable-next-line no-undef
      window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
      return defaultValue;
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = newValue => {
    try {
      // eslint-disable-next-line no-undef
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {
      console.error(err);
    }
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
}
