import React, { useState } from "react";
import createHtmlPage from "../../util/publishWebpage";
import { myPageDataEnum, usePreviewData } from "../../hooks/usePreviewData";
import useLocalStorage from "../../hooks/useLocalStorage";
import Preview from "./Preview";

export default function PreviewWrapper({
  editEnabled = false,
  previewData = null,
}) {
  const { myPageData } = usePreviewData(previewData);

  const [showToolBar, setShowToolBar] = useState(false);
  // TODO: Refactor this data check
  const [formData, setFormData] = useLocalStorage("formData", null);
  // TODO: Change this webpallet setup
  const [webPalette, setWebPalette] = useState(0);
  const [editModeEnabled, setEditModeEnabled] = useState(editEnabled);
  const [previewMode, setPreviewMode] = useState(false);

  const [publishPreview, setPublishPreview] = useState("");

  function savePageData() {
    console.log("updated data:", myPageData);
  }

  function toggleToolBar() {
    // Enable edit mode if showToolBar is being toggled on
    if (!showToolBar) {
      setEditModeEnabled(true);
    }
    setShowToolBar(val => !val);
  }

  function toggleEditMode() {
    setEditModeEnabled(val => !val);
  }

  function togglePreviewMode() {
    setEditModeEnabled(false);
    setPreviewMode(true);
  }

  // TODO: Finish proper implementation
  function publishWebpage() {
    console.log("Publish Webpage");
    const pageHTML = createHtmlPage(
      // eslint-disable-next-line no-undef
      document.getElementById("previewWebpage").innerHTML,
      "example author",
      "example page",
      "example description",
    );
    /*
    var tempEl = document.createElement('a');

    tempEl.href = 'data:attachment/text,' + encodeURI(pageHTML);
    tempEl.target = '_blank';
    tempEl.download = 'mysite.html';
    tempEl.click();
    */

    setPreviewMode(false);
    setShowToolBar(true);

    return pageHTML;
  }

  function resetWebpageData() {
    setFormData(null);
    // eslint-disable-next-line no-undef
    window.location.reload();
  }
  /* TODO: Remove the need for the useEffect when going to publish mode...
  useEffect(() => {
    if (previewMode && !editModeEnabled) {
      setPublishPreview(publishWebpage());
    }
  }, [previewMode, editModeEnabled]);
  */

  return (
    <div>
      {publishPreview && (
        <div>
          <div
            style={{
              position: "fixed",
              top: "0",
              zIndex: "4",
              border: "2px dotted rgb(255, 65, 65)",
            }}
          >
            <p>Publish Preview</p>
          </div>
          <iframe
            style={{
              position: "fixed",
              top: "0",
              right: "0",
              zIndex: "3",
              width: "100%",
              height: "100%",
            }}
            title="publishPreview"
            srcDoc={publishPreview}
          />
        </div>
      )}
      {!previewMode && (
        <div
          style={{
            backgroundColor: "rgba(35, 39, 47, 0.5)",
            position: "fixed",
            top: "0",
            right: "0",
            zIndex: "3",
            padding: "5px",
          }}
        >
          <button
            type="button"
            style={{ position: "fixed", top: "0", right: "0", zIndex: "4" }}
            onClick={() => toggleToolBar()}
          >
            {showToolBar ? "X" : editModeEnabled ? "🔧 Settings" : "✨ Edit"}
          </button>
          {showToolBar && (
            <div>
              <h4 style={{ color: "#fff" }}>Advanced Settings</h4>
              <p style={{ color: "#fff" }}>Color Palette: </p>
              <select
                defaultValue={webPalette}
                // @ts-ignore
                onChange={val => setWebPalette(val.target.value)}
              >
                <option value={0}>morning forrest</option>
                <option value={1}>in the fairway</option>
              </select>
              <br />
              <p style={{ color: "#fff" }}>Background Image: </p>
              <img
                width="200px"
                src={myPageData.get(myPageDataEnum.backgroundImg)?.value}
                alt=""
              />
              <input
                type="file"
                id="myImage"
                accept="image/*"
                onChange={event => {
                  // TODO: Will need to upload to s3 for proper url and storage
                  console.log(event.target.files[0]);
                  myPageData
                    .get(myPageDataEnum.backgroundImg)
                    ?.setter(`${URL.createObjectURL(event.target.files[0])}`);
                }}
              />
              {formData && (
                <button type="button" onClick={() => resetWebpageData()}>
                  Reset Data
                </button>
              )}
              {/* <button onClick={() => togglePreviewMode()}>Publish Webpage</button> */}
              <button type="button" onClick={() => savePageData()}>
                Save Data
              </button>
            </div>
          )}
        </div>
      )}
      <div id="previewWebpage">
        <Preview
          webPalette={webPalette}
          webBackground={myPageData.get(myPageDataEnum.backgroundImg)?.value}
          editModeEnabled={editModeEnabled}
          toggleEditMode={toggleEditMode}
          myPageData={myPageData}
        />
      </div>
    </div>
  );
}
