import React, { useState, useRef, useLayoutEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { TfiClose } from "react-icons/tfi";

import "./Home.css";
import { useAuth } from "../../auth/AuthProvider";

export default function Home() {
  const ref = useRef(null);
  const [openMenu, setOpenMenu] = useState(true);
  const [width, setWidth] = useState(0);
  const { session } = useAuth();

  useLayoutEffect(() => {
    if (ref?.current) {
      setWidth(ref.current.clientWidth);
    }
  }, []);

  function toggleOpenMenu(onlyMobile = false) {
    if (onlyMobile) {
      if (width < 600) {
        setOpenMenu(val => !val);
      }
    } else {
      setOpenMenu(val => !val);
    }
  }

  return (
    <div className="home" ref={ref}>
      {session && (
        <HomeMenu openMenu={openMenu} toggleOpenMenu={toggleOpenMenu} />
      )}
      {session && (
        <div className="homeOutlet">
          <Outlet />
        </div>
      )}
      {!session && (
        <div>
          You are not authorized to see this page please{" "}
          <Link to="/login">Login</Link>
        </div>
      )}
    </div>
  );
}

function HomeMenu({ openMenu, toggleOpenMenu }) {
  return (
    <>
      <button
        type="button"
        className="sideBarMobileButton"
        onClick={() => toggleOpenMenu()}
      >
        {openMenu ? <TfiClose /> : <RxHamburgerMenu />}
      </button>
      <div className="sideBar" style={{ display: openMenu ? "block" : "none" }}>
        <div className="sideBarTitle">
          <h3>Welcome to the beta</h3>
        </div>
        <div className="sideBarDash" />
        <div className="sideBarOptions">
          <Link to="/home/account" onClick={() => toggleOpenMenu(true)}>
            Account
          </Link>
          <Link to="/home/example#top" onClick={() => toggleOpenMenu(true)}>
            Example Mode
          </Link>
          <Link to="/home/empty#top" onClick={() => toggleOpenMenu(true)}>
            Empty Mode
          </Link>
          <Link to="/home/form#top" onClick={() => toggleOpenMenu(true)}>
            Form Mode
          </Link>
        </div>
      </div>
    </>
  );
}
