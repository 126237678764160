import React from "react";
import "./Login.css";
import { useAuth } from "../../auth/AuthProvider";

export default function Login() {
  const webBackground = `${process.env.PUBLIC_URL}/abstract-bg.jpg`;
  const { login } = useAuth();

  return (
    <div
      className="login-container"
      style={{ backgroundImage: `url(${webBackground})` }}
    >
      <div className="login-box">
        <h2>
          Login to Easyzilla <b>beta</b>
        </h2>
        {login()}
      </div>
    </div>
  );
}
