import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import ErrorPage from "./error-page";
import LandingPage from "./pages/LandingPage/LandingPage";
import Login from "./pages/Login/Login";
import Logout from "./pages/Login/Logout";
import ExampleMode from "./pages/Home/ExampleMode";
import EmptyMode from "./pages/Home/EmptyMode";
import FormMode from "./pages/Home/FormMode";

import Home from "./pages/Home/Home";
import Account from "./pages/Home/Account/Account";
import AuthProvider from "./auth/AuthProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "logout",
    element: <Logout />,
  },
  {
    path: "home",
    element: <Home />,
    children: [
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "example",
        element: <ExampleMode />,
      },
      {
        path: "empty",
        element: <EmptyMode />,
      },
      {
        path: "form",
        element: <FormMode />,
      },
    ],
  },
]);

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>,
);
