import React from "react";
import "./LandingPage.css";
import { Link } from "react-router-dom";

export default function LandingPage() {
  const webPalette = 0;
  const webBackground = `${process.env.PUBLIC_URL}/abstract-bg.jpg`;

  const primaryColor = ["247, 247, 247", "247, 247, 247"];
  const primaryFont = "247, 247, 247";
  const secondaryFont = "0, 0, 0";
  const accentColor = "174, 182, 207";

  return (
    <div
      id="webpage"
      className="webpageLP"
      style={{ backgroundImage: `url(${webBackground})` }}
    >
      <div id="top" />
      <div
        className="topLP"
        style={{
          backgroundColor: `rgba(${primaryColor[webPalette]}, 0.2)`,
          color: `rgb(${primaryFont})`,
        }}
      >
        <div className="topContainerLP">
          <div className="topTitleLP">
            <h1>Easyzilla</h1>
          </div>
          <div className="topDescLP">
            <h3>
              Set up a professional looking webpage in minutes. No coding, No
              designing, No hassle. Join our wait-list, send us an email{" "}
              <a href="mailto:hello@easyzilla.com">hello@easyzilla.com</a>
            </h3>
          </div>
          <div className="coreButtonLP">
            <Link
              className="mainButtonLP"
              style={{
                backgroundColor: `rgba(${accentColor}, 1)`,
                color: `rgb(${secondaryFont})`,
              }}
              to="/login"
            >
              Login to Beta
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
